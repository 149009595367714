<template>
  <div class="flex items-center gap-3 w-full mb-4">
    <!-- search input --->
    <searchbar
      :id="'search'"
      :label="$t('search')"
      v-model="state.search"
      :labelClass="'bg-white-dark'"
      :activeColor="'primary'"
      class="w-full sm:w-1/3 "
      :placeHolder="$t('search')"
    />
    <!--- add location button-->
    <div
      class="ml-auto flex items-center cursor-pointer mr-1 bg-primary px-2 py-2 rounded-lg"
      @click="add_location()"
    >
      <div class="mr-0 sm:mr-2"><plus-icon :fillClass="'fill-white'" /></div>
      <div class="text-md text-white font-semibold hidden sm:block ">
        {{ $t("add_location") }}
      </div>
    </div>
  </div>

  <!-- Content-->
  <div v-if="filteredPositions != null && filteredPositions.length > 0">
    <div
      class="bg-white cursor-pointer rounded-md h-auto flex-1 gap-8 mb-3 hover:border-grey-fontgrey"
      :class="
        GET_CUSTOMER_POSITION.id == position.id
          ? 'border-t-4 border-primary'
          : 'border border-transparent'
      "
      v-for="position of filteredPositions"
      :key="position.id"
      :position="position"
      @click="edit_location(position)"
    >
      <positions-container
        :editContainer="true"
        @editLocation="edit_location(position)"
        :position="position"
      />
    </div>
  </div>

  <!--empty feedback-->
  <div v-else>
    <empty-list-feedback
      class="pt-10 flex justify-center items-center"
      v-if="filteredPositions == null || filteredPositions.length == 0"
      :text="$t('no_position')"
    ></empty-list-feedback>
  </div>

  <!--- edit location modal -->
  <add-location-modal
    :fetchProp="false"
    :isVisible="state.openModal"
    @closeModal="set_back()"
  />
</template>

<script>
import { computed, defineComponent, reactive } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import EmptyListFeedback from "../../../components/global/EmptyListFeedback.vue";
import Searchbar from "../../../components/layout/Searchbar.vue";
import { CUSTOMER_POSITIONS_TYPES, STORE_TYPES } from "../../../store/types";
import AddLocationModal from "../../finders/modals/AddLocationModal.vue";
import PositionsContainer from "../components/LocationsContainer.vue";

export default defineComponent({
  components: {
    Searchbar,
    AddLocationModal,
    PositionsContainer,
    EmptyListFeedback,
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    /** State */
    const state = reactive({
      openModal: false,
      search: "",
    });

    /*
     * Computeds
     */
    const GET_CUSTOMER_POSITIONS = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_POSITIONS}/${CUSTOMER_POSITIONS_TYPES.GET_CUSTOMER_POSITIONS}`
      ];
    });
    const GET_CUSTOMER_POSITION = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_POSITIONS}/${CUSTOMER_POSITIONS_TYPES.GET_CUSTOMER_POSITION}`
      ];
    });
    const filteredPositions = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_POSITIONS}/${CUSTOMER_POSITIONS_TYPES.GET_CUSTOMER_POSITIONS}`
      ].filter((search) => {
        return search.name.toLowerCase().match(state.search.toLowerCase());
      });
    });
    /** Functions */
    const edit_location = (position) => {
      state.openModal = true;
      SET_CUSTOMER_POSITION(position);
    };
    const add_location = () => {
      state.openModal = true;
    };

    const set_back = () => {
      SET_CUSTOMER_POSITION({});
      state.openModal = false;
    };

    const SET_CUSTOMER_POSITION = (position) => {
      return store.commit(
        `${STORE_TYPES.CUSTOMER_POSITIONS}/${CUSTOMER_POSITIONS_TYPES.SET_CUSTOMER_POSITION}`,
        position
      );
    };

    return {
      store,
      state,
      route,
      edit_location,
      add_location,
      GET_CUSTOMER_POSITIONS,
      filteredPositions,
      GET_CUSTOMER_POSITION,
      set_back,
    };
  },
});
</script>
