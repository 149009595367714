<template>
  <div class="p-3">
    <!-- Title -->
    <div class="flex items-center gap-2">
      <div class="font-semibold flex gap-2">
        <div>{{ position.name }}</div>
      </div>

      <div
        class="ml-auto rounded-full w-10 h-10 items-center flex hover:bg-primary-lightest "
        @click.stop="$emit('editLocation')"
      >
        <div class="cursor-pointer">
          <div class="ml-2"><edit-icon /></div>
        </div>
      </div>
    </div>
    <!-- --------- -->

    <div class="mt-3" v-if="editContainer && position.parent != null">
      <div class="text-md text-blue">{{ $t("superior_location") }}:</div>
      <div class="text-md">
        {{ position.parent.name }}
      </div>
    </div>
    <div class="text-grey-fontgrey text-sm" v-if="!editContainer">
      {{ $t("device_id") }}: {{ position.network_address }}
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import EditIcon from "../../../components/icons/EditIcon.vue";

export default defineComponent({
  components: { EditIcon },
  name: "Location",
  props: {
    editContainer: {
      type: Boolean,
      default: false,
      required: false,
    },
    position: {
      type: Object,
    },
  },

  setup() {
    const store = useStore();

    /** State */
    const state = reactive({
      hover: {
        type: Boolean,
        default: false,
      },
    });

    /**
     * Computeds
     */

    /**
     * Functions
     */

    return {
      store,
      state,
    };
  },
});
</script>
