<template>
  <main class="flex flex-col items-center">
    <main-content-container>
      <h2 class="font-semibold mb-6 mt-3 font-roboto text-xlg text-black">
        {{ $t("locations") }}
      </h2>

      <positions />
    </main-content-container>
  </main>
</template>

<script>
import { computed, defineComponent, reactive, watch } from "vue";
import { useStore } from "vuex";

import MainContentContainer from "../../components/global/MainContentContainer.vue";
import {
  AUTH_TYPES,
  CUSTOMER_COLLIS_TYPES,
  CUSTOMER_POSITIONS_TYPES,
  STORE_TYPES,
} from "../../store/types";
import Positions from "./show/Locations.vue";

export default defineComponent({
  components: {
    MainContentContainer,
    Positions,
  },
  setup() {
    const store = useStore();

    /** State */
    const state = reactive({});

    /** COMPUTEDS  */
    const GET_USER = computed(
      () => store.getters[`${STORE_TYPES.AUTH}/${AUTH_TYPES.GET_USER}`]
    );

    function FETCH_CUSTOMER_COLLIS() {
      store.dispatch(
        `${STORE_TYPES.CUSTOMER_COLLIS}/${CUSTOMER_COLLIS_TYPES.FETCH_CUSTOMER_COLLIS}`,
        {
          id: GET_USER.value.customer_id,
        }
      );
    }
    function FETCH_POSITIONS() {
      store.dispatch(
        `${STORE_TYPES.CUSTOMER_POSITIONS}/${CUSTOMER_POSITIONS_TYPES.FETCH_CUSTOMER_POSITIONS}`,
        GET_USER.value.customer_id
      );
    }

    watch(
      () => GET_USER.value,
      (newValue) => {
        if (newValue != null && newValue.customer_id != null) {
          FETCH_CUSTOMER_COLLIS(newValue.customer_id);
          FETCH_POSITIONS(newValue.customer_id);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    return {
      state,
    };
  },
});
</script>
